import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes, rulebookZone } from '@stlc/vega/utils';

export const strikeZoneSpec: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 240,
    autosize: { type: 'none', resize: false, contains: 'padding' },
    config: {
        signals: [
            { name: 'backgroundColor', value: 'white' },
            { name: 'textColor' },
            { name: 'gridColor' },
            { name: 'labelColor' },
            { name: 'tickColor' },
            { name: 'titleColor' },
        ],
        axis: {
            gridColor: { signal: "gridColor || darkTheme ? '#dddddd' : '#dddddd'" },
            gridOpacity: 1,
            labelColor: {
                signal: "labelColor || darkTheme ? '#cccccc' : '#333333'",
            },
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: { signal: "tickColor || darkTheme ? '#999999' : '#999999'" },
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: {
                signal: "titleColor || darkTheme ? '#cccccc' : '#333333'",
            },
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fill: { signal: "textColor || (darkTheme ? '#cccccc' : '#333333')" },
        },
        events: { defaults: { allow: ['wheel', 'touchstart'] } },
    },
    signals: [
        { name: 'darkTheme', value: false },
        {
            name: 'background',
            update: "backgroundColor || (darkTheme ? '#333333' : 'transparent')",
        },
        { name: 'defaultWidth', value: 240 },
        { name: 'defaultHeight', value: 300 },
        { name: 'strikeZoneStroke', update: "darkTheme ? '#ffffff' : '#000000'" },
        { name: 'strikeZoneStrokeOpacity', value: 0.3 },
        { name: 'strikeZoneFill', value: 'transparent' },
        { name: 'insufficientData', update: 'dataCount < 30' },
        { name: 'xAxisDomain', value: [-2, 2] },
        { name: 'yAxisDomain', value: [-0.75, 4.25] },
        { name: 'xAxisField', value: 'plateSide' },
        { name: 'yAxisField', value: 'plateHeight' },
        { name: 'decisionType', value: 'swings' },
        { name: 'highlightTwoStrikePitches', value: false },
        { name: 'height', update: '(width / defaultWidth) * defaultHeight' },
        { name: 'takeRunsSavedDomain', value: [-0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3] },
        { name: 'swingRunsSavedDomain', value: [-1, -0.5, -0.25, 0, 0.1, 0.175, 0.35] },
        { name: 'reverseColors', value: false },
        {
            name: 'dataCount',
            update: "length(data('dataAggregate')) == 1 ? data('dataAggregate')[0].count : null",
        },
        {
            name: 'visibleCount',
            update: "length(data('visibleData'))",
        },
        { name: 'bandwidth', value: 10 },
        { name: 'perspective', value: 'batter' },
        { name: 'zoneType', value: '9-box' },
        {
            name: 'label',
            value: {
                pitcher: 'Perspective: Pitcher',
                batter: 'Perspective: Batter',
            },
        },
        // { name: 'batterBats' },
        // { name: 'pitchTypes' },
        // { name: 'pitchType' },
        // {
        //     name: 'pitchHover',
        //     update: '',
        //     on: [
        //         { events: 'symbol:mouseover', update: 'group().datum' },
        //         { events: 'symbol:mouseout', update: "''" },
        //     ],
        // },
        { name: 'pitchesCountKey', value: 'stat:numberOfPitches_count' },
        { name: 'selectedIds', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        { name: 'minX', update: 'xAxisDomain[0]' },
        { name: 'maxX', update: 'xAxisDomain[1]' },
        { name: 'showPitchNumbers', value: false },
        { name: 'actualPitchSize', value: false },
        {
            name: 'pitchSize',
            update: 'PI * pow((width / (maxX - minX)) * (0.125 / (showPitchNumbers || actualPitchSize ? 1 : 1.5)), 2)',
        },
        { name: 'heatmapType', value: 'pitch-density' },
        { name: 'runsSavedColor', value: '' },
        { name: 'decision', value: '' },
        {
            name: 'showHeatmapData',
            value: false,
            description: 'Show Heatmap data from outside the spec',
        },
        {
            name: 'toggleHeatmapData',
            value: false,
            update: '!heatmapType',
            description: 'Show Heatmap data from inside the spec',
            on: [
                {
                    events: [{ type: 'click', markname: 'pitchesText' }],
                    update: '!showHeatmapData && (!heatmapType || !toggleHeatmapData)',
                },
                {
                    events: { signal: 'showHeatmapData' },
                    update: 'false',
                },
            ],
        },
        {
            name: 'showData',
            update: 'showHeatmapData || toggleHeatmapData',
        },
        {
            name: 'showHeatmap',
            update: '!!heatmapType && !insufficientData && !showData',
        },
        { name: 'colorField', value: 'pitchType' },
        { name: 'hoveredId', value: null },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'selectable', value: true },
        { name: 'brushable', value: false },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'selectable ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                            filter: ['event.item.mark.name !== "pitchesText"'],
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                            filter: ['event.item.mark.name !== "pitchesText"'],
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                            filter: ['event.item.mark.name !== "pitchesText"'],
                        },
                        {
                            type: 'touchstart',
                            filter: ['event.item.mark.name !== "pitchesText"'],
                        },
                    ],
                    update: '!selectable && brushable ? true : false',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
        { name: 'videoLoadedLazily', value: true },
    ],
    data: [
        {
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'formula',
                    as: 'runValue',
                    expr: "(reverseColors && datum.actualRunsSaved < 0) || (!reverseColors && datum.actualRunsSaved > 0) ? 'good' : 'bad'",
                },
                {
                    type: 'filter',
                    expr: [
                        'isValid(datum[yAxisField])',
                        'isValid(datum[xAxisField])',
                        `(${[
                            "(decision !== 'good' && decision !== 'bad')",
                            "(decision == 'good' && ((datum.swingDecisionOpportunities > 0 && datum.goodSwings > 0) || (datum.takeDecisionOpportunities > 0 && datum.goodTakes > 0)))",
                            "(decision == 'bad' && ((datum.swingDecisionOpportunities > 0 && datum.goodSwings == 0) || (datum.takeDecisionOpportunities > 0 && datum.goodTakes == 0)))",
                        ].join(' || ')})`,
                        "(runsSavedColor == datum.runValue || (runsSavedColor != 'bad' && runsSavedColor != 'good'))",
                    ].join(' && '),
                },
                {
                    type: 'extent',
                    field: 'actualRunsSaved',
                    signal: 'actualRunsSavedExtent',
                },
                {
                    type: 'formula',
                    as: 'value',
                    expr: 'actualRunsSavedExtent[0] < 0 && actualRunsSavedExtent[1] > 0 ? datum.actualRunsSaved / (datum.actualRunsSaved < 0 ? actualRunsSavedExtent[0] : actualRunsSavedExtent[1]) : actualRunsSavedExtent[0] < 0 ? (actualRunsSavedExtent[1] - datum.actualRunsSaved) / (actualRunsSavedExtent[1] - actualRunsSavedExtent[0]) : datum.actualRunsSaved / actualRunsSavedExtent[1]',
                },
                // { type: 'extent', field: 'value', signal: 'valueExtent' },
                {
                    type: 'collect',
                    sort: {
                        field: [{ expr: 'abs(datum.value)' }],
                        order: ['ascending'],
                    },
                },
            ],
        },
        {
            name: 'lastFiftyData',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: ['id'],
                    ops: ['count'],
                    as: ['count'],
                },
                {
                    type: 'filter',
                    expr: 'datum.count < 51',
                },
            ],
        },
        {
            name: 'dataAggregate',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: [{ signal: 'yAxisField' }],
                    ops: ['count'],
                    as: ['count'],
                },
            ],
        },
        {
            name: 'densityData',
            source: 'data',
            transform: [
                {
                    type: 'kde2d',
                    size: [{ signal: 'width' }, { signal: 'height' }],
                    x: { expr: "scale('x', datum[xAxisField])" },
                    y: { expr: "scale('y', datum[yAxisField])" },
                    counts: true,
                    cellSize: 2,
                    bandwidth: { signal: '[bandwidth, bandwidth]' },
                    weight: { field: 'value' },
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '(!brushedIds && !selectedIds) || (!brushedIds && selectedIds && length(selectedIds) > 0 && indexof(selectedIds, datum.id) >= 0) || (brushedIds && length(brushedIds) > 0 && indexof(brushedIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!showHeatmap && ((!brushedIds && selectedIds && (length(selectedIds) == 0 || indexof(selectedIds, datum.id) == -1)) || (brushedIds && (length(brushedIds) == 0 || indexof(brushedIds, datum.id) == -1)))',
                },
            ],
        },
        // { name: 'ellipsesData', values: [] },
        {
            name: 'homePlateData',
            values: [
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.694167, y: 0.08 },
                { perspective: 'pitcher', x: 0, y: 0.18 },
                { perspective: 'pitcher', x: -0.694167, y: 0.08 },
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.694167, y: 0.1 },
                { perspective: 'batter', x: 0, y: 0 },
                { perspective: 'batter', x: -0.694167, y: 0.1 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
            ],
            transform: [{ type: 'filter', expr: 'perspective === datum.perspective' }],
        },
        {
            name: 'brushedData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: [
                        // 'datum.plateSide >= brushX1 && datum.plateSide >= brushX2',
                        // 'datum.plateHeight <= brushY1 && datum.plateHeight >= brushY2',
                        'inrange(datum[xAxisField], brushX)',
                        'inrange(datum[yAxisField], brushY)',
                        // '!inrange(selectedIds, datum.id)',
                    ].join(' && '),
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: { signal: 'xAxisDomain' },
            range: 'width',
            clamp: true,
            reverse: { signal: "perspective !== 'pitcher'" },
        },
        {
            name: 'y',
            type: 'linear',
            domain: { signal: 'yAxisDomain' },
            range: 'height',
            zero: false,
            clamp: true,
        },
        {
            name: 'pitch-densityHeatmapColor',
            type: 'linear',
            domain: [0, 0.1, 0.3, 0.5, 0.65, 0.8, 0.9, 0.95, 0.985, 1],
            range: ['#ffffff', '#ffffcc', '#ffeda0', '#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c', '#bd0026'],
        },
        {
            name: 'pitch-densityHeatmapOpacity',
            type: 'linear',
            domain: [0, 0.1, 0.3, 0.5, 0.65, 0.8, 0.9, 0.95, 0.985, 1],
            range: [0, 0.5, 1, 1, 1, 1, 1, 1, 1],
        },
        {
            name: 'run-value-densityHeatmapColor',
            type: 'linear',
            domain: [{ signal: '0' }, { signal: '1' }],
            range: ['#d4322c', '#f16e43', '#fcac64', '#fedd90', '#faf8c1', '#dcf1ec', '#abd6e8', '#75abd0', '#4a74b4'],
            reverse: { signal: "runsSavedColor != 'bad'" },
        },
        {
            name: 'run-value-densityHeatmapOpacity',
            type: 'linear',
            range: [1, 1, 1, 1, 1, 1, 1, 1, 1],
        },
        {
            name: 'actualRunsSavedColor',
            type: 'quantile',
            domain: {
                signal: "decisionType == 'swings' ? swingRunsSavedDomain : takeRunsSavedDomain",
            },
            range: ['#2166ac', '#67a9cf', '#d1e5f0', '#dddddd', '#fddbc7', '#ef8a62', '#b2182b'],
            reverse: { signal: 'reverseColors' },
        },
        {
            name: 'actualRunsSavedStrokeColor',
            type: 'quantile',
            domain: {
                signal: "decisionType == 'swings' ? swingRunsSavedDomain : takeRunsSavedDomain",
            },
            range: ['#1d5b9a', '#4f9bc7', '#afd1e4', '#c6c6c6', '#fbbe9b', '#eb7343', '#a01526'],
            reverse: { signal: 'reverseColors' },
        },
        {
            name: 'pitchTypeColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
        {
            name: 'pitchTypeStrokeColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
    ],
    marks: [
        {
            type: 'image',
            from: { data: 'densityData' },
            encode: {
                enter: {
                    x: { value: 0 },
                    y: { value: 0 },
                    aspect: { value: false },
                    smooth: { value: true },
                },
                update: {
                    cursor: { signal: 'brushable ? "crosshair" : "default"' },
                    width: { signal: 'width' },
                    height: { signal: 'height' },
                    opacity: { signal: 'showHeatmap ? 1 : 0' },
                },
            },
            transform: [
                {
                    type: 'heatmap',
                    field: 'datum.grid',
                    color: { expr: "scale(heatmapType + 'HeatmapColor', datum.$value / datum.$max)" },
                    opacity: {
                        expr: "scale(heatmapType + 'HeatmapOpacity', datum.$value / datum.$max) * (insufficientData ? 0 : 1)",
                    },
                },
            ],
        },
        {
            type: 'line',
            interactive: false,
            from: { data: 'homePlateData' },
            encode: {
                enter: {
                    strokeWidth: { value: 1 },
                    strokeCap: { value: 'square' },
                    fillOpacity: { value: 0.033 },
                    strokeOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    fill: { signal: "darkTheme ? '#ffffff' : '#000000'" },
                    stroke: { signal: "darkTheme ? '#ffffff' : '#000000'" },
                },
            },
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                },
                update: {
                    fill: { signal: 'strikeZoneFill' },
                    fillOpacity: {
                        signal: "zoneType != 'percentile-box' ? 0.04901960784313722 : 0",
                    },
                },
            },
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#cccccc' },
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: { signal: 'xAxisField' } },
                    y: { scale: 'y', field: { signal: 'yAxisField' } },
                    // fill: {
                    //     scale: { signal: "colorField + 'Color'" },
                    //     field: { signal: 'colorField' },
                    // },
                    size: {
                        signal: 'insufficientData || !heatmapType || showData ? pitchSize : (pitchSize / 4)',
                    },
                    opacity: { signal: '!showHeatmap || hasSelected ? 1 : 0' },
                    // undefined: { signal: 'showHeatmap || hasSelected ? true : false' },
                },
            },
        },
        {
            type: 'text',
            name: 'pitchesText',
            from: { data: 'dataAggregate' },
            interactive: true,
            encode: {
                enter: {
                    y: { signal: 'height' },
                    x: { signal: 'width / 2' },
                    align: { value: 'center' },
                    baseline: { value: 'bottom' },
                    fillOpacity: { value: 0.65 },
                },
                update: {
                    cursor: { signal: "!!heatmapType ? 'pointer' : 'default'" },
                    fill: {
                        signal: "!showData && !insufficientData && (heatmapType == 'run-value-density') ? 'white' : '#808080'",
                    },
                    text: {
                        signal: "visibleCount > 0 && visibleCount !== datum.count ? stlcTranslate('playerCharts:selectedXOfY', { defaultValue: 'Selected {{ x }} of {{ y }}', x: visibleCount, y: datum.count }) : stlcTranslate(pitchesCountKey, { count: datum.count })",
                    },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: 'showHeatmap || ((brushedIds || selectedIds) && !isBrushedOrSelected)' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && (videoLoadedLazily || isArray(parent.videos) && length(parent.videos) > 0)',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            fill: { value: 'white' },
                            strokeOpacity: { value: 1 },
                        },
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: { signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI' },
                            stroke: { scale: 'pitchTypeColor', signal: 'parent[colorField]' },
                            strokeWidth: { signal: 'min(max(1, pow((sqrt(pitchSize / PI) + 3), 2) * PI / 32), 2)' },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: { signal: 'pitchSize' },
                            fill: {
                                signal: "showHeatmap && hasSelected ? '#0a2f5f' : showHeatmap && !hasSelected ? 'transparent' : scale(colorField + 'Color', parent[colorField])",
                            },
                            fillOpacity: {
                                // signal: '(insufficientData ? 0.5 : showHeatmap && hasSelected ? 0.25 : !heatmapType || showData ? 0.5 : hasSelected ? 0.4 : 0.4) * (parent.id === hoveredId ? 2 : 1)',
                                signal: 'insufficientData ? 0.5 : (showHeatmap && hasSelected) ? 0.25 : (!heatmapType || showData) ? ((currentId && currentId === parent.id) || (hoveredId && hoveredId == parent.id) ? 1 : 0.5) : 0',
                            },
                            stroke: {
                                signal: "showHeatmap && !hasSelected ? 'transparent' : scale(colorField + 'StrokeColor', parent[colorField])",
                            },
                            strokeOpacity: {
                                signal: 'showHeatmap && hasSelected ? 0 : (!heatmapType || showData) && hasSelected ? 1 : 0.75',
                            },
                            strokeWidth: {
                                signal: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds)) ? 1 : 0',
                            },
                            opacity: { signal: '!showHeatmap || hasSelected ? 1 : 0' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                        },
                    },
                },
                {
                    type: 'symbol',
                    encode: {
                        update: {
                            x: { scale: 'x', field: { signal: 'xAxisField' } },
                            y: { scale: 'y', field: { signal: 'yAxisField' } },
                            fill: { value: 'black' },
                            fillOpacity: { value: 0.3 },
                            opacity: { signal: 'highlightTwoStrikePitches && datum.preStrikes == 2 ? 1 : 0' },
                        },
                    },
                },
            ],
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType != 'none' ? strikeZoneStrokeOpacity : 0",
                    },
                    stroke: { signal: 'strikeZoneStroke' },
                },
            },
        },
        {
            type: 'path',
            interactive: false,
            encode: {
                enter: { fill: { value: '#939597' }, stroke: { value: '#652c90' } },
                update: {
                    y: { value: rulebookZone.y, scale: 'y' },
                    x: {
                        signal: `(perspective == 'pitcher' ? -1 : 1) * ${rulebookZone.width / 2}`,
                        scale: 'x',
                    },
                    scaleY: { signal: 'height / defaultHeight' },
                    scaleX: { signal: 'width / defaultWidth' },
                    path: {
                        value: 'M0,36 H85 M0,72 H85 M28.3333333333,0 V109 M56.6666666667,0 V109',
                    },
                    strokeWidth: { value: 1 },
                    strokeDash: { value: [5, 5] },
                    stroke: { signal: 'strikeZoneStroke' },
                    opacity: {
                        signal: "zoneType == '9-box' ? strikeZoneStrokeOpacity : 0",
                    },
                },
            },
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
