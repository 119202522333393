<div class="flex space-x-4 mb-2">
    <img
        [stlcProtectedSrc]="
            '/img/players/' +
            player.player.sourceIdUniverse +
            '/' +
            player.player.sourceId +
            '/headshot/' +
            player.player.headshot.imageFilename +
            '?hash=' +
            player.player.headshot.hash
        "
        class="rounded-xl"
        height="75"
        width="50"
    />
    <div class="flex-1 flex flex-col pb" [ngClass]="{ 'border-b border-gray-100': divider }">
        <div class="flex flex-col space-y-1">
            <div class="flex space-x-2 items-center density-3">
                <a *ngIf="groups.length > 0" class="heading-link text-3xl" [matMenuTriggerFor]="optionsMenu">{{
                    player.player | stlcPlayerName
                }}</a>
                <span *ngIf="groups.length === 0" class="text-3xl cursor-default">{{ player | stlcPlayerName }}</span>
                <a
                    mat-icon-button
                    target="_blank"
                    [href]="'/player/' + player.player.sourceIdUniverse + '/' + player.player.sourceId"
                >
                    <mat-icon>open_in_new</mat-icon>
                </a>
            </div>
            <div *ngIf="!!team" class="flex space-x-4 text-gray-400">
                <span>{{ team | stlcPlayerOrgClassLevel }}</span>
                <ul class="stlc-list-inline-vbar">
                    <li [innerHTML]="player.displayPosition"></li>
                    <li>{{ player.player.bats }} / {{ player.player.throws }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="flex justify-between">
    <div class="flex flex-col items-start density-3">
        <ng-container *ngIf="!!prev">
            <button
                mat-button
                color="primary"
                class="min-w-0"
                [disabled]="disabled"
                (click)="onPlayerChanged(prev.value)"
            >
                <mat-icon>arrow_back</mat-icon> {{ prev.text }}
            </button>
        </ng-container>
    </div>
    <div class="flex flex-col items-end density-3">
        <ng-container *ngIf="!!next">
            <button
                mat-button
                color="primary"
                [disabled]="disabled"
                class="min-w-0"
                (click)="onPlayerChanged(next.value)"
            >
                {{ next.text }} <mat-icon>arrow_forward</mat-icon>
            </button>
        </ng-container>
    </div>
</div>
<mat-menu class="stlc-mat-menu-dense max-w-none w-full md:w-auto" #optionsMenu="matMenu">
    <div
        class="grid divide-x divide-gray-100 border-gray-100 w-full"
        [ngClass]="{
            'grid-cols-3': groups.length > 2,
            'grid-cols-2': groups.length === 2,
            'grid-cols-1': groups.length === 1
        }"
    >
        <div *ngFor="let group of groups" class="max-w-none w-48 md:w-auto">
            <div class="stlc-menu-header">
                {{ group.headerKey ? (group.headerKey | i18next : { defaultValue: group.header }) : group.header }}
            </div>
            <mat-action-list class="flex flex-col pt-0">
                <ng-container *ngFor="let option of group.options">
                    <button
                        mat-list-item
                        class="text-sm h-10 whitespace-nowrap"
                        [class.mdc-list-item--selected]="option.selected"
                        [class.text-gray-400]="option.disabled"
                        (click)="player === option.value ? undefined : onPlayerChanged(option.value)"
                    >
                        <span matListItemTitle>
                            <span
                                class="sm:inline"
                                [innerHTML]="
                                    option.textKey
                                        ? (option.textKey | i18next : { defaultValue: option.text })
                                        : option.text
                                "
                            ></span>
                        </span>
                    </button>
                </ng-container>
            </mat-action-list>
        </div>
    </div>
</mat-menu>
