import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import {
    AdvanceScoutingPlayerFragment,
    AdvanceScoutingPlayerSelectOption,
    AdvanceScoutingPlayerSelectOptionGroup,
    AdvanceScoutingTeamFragment,
} from '@stlc/advance-scouting/data-access';
import { StlcImgDefaultDirective, StlcProtectedSrcDirective } from '@stlc/angular/directives';
import { StlcPlayerNamePipe, StlcPlayerOrgClassLevelPipe } from '@stlc/angular/pipes';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain } from '@stlc/lodash';

@Component({
    selector: 'advance-scouting-ui-player-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatListModule,
        StlcPlayerNamePipe,
        StlcPlayerOrgClassLevelPipe,
        StlcProtectedSrcDirective,
        StlcImgDefaultDirective,
        StlcI18nModule,
    ],
    templateUrl: './player-header.component.html',
    styleUrls: ['./player-header.component.scss'],
})
export class AdvanceScoutingPlayerHeaderComponent implements OnChanges {
    @Input()
    player: AdvanceScoutingPlayerFragment;

    @Input()
    team: AdvanceScoutingTeamFragment;

    @Input()
    divider = true;

    @Input()
    groups: Array<AdvanceScoutingPlayerSelectOptionGroup> = [];

    @Input()
    disabled = false;

    @Output()
    playerChange = new EventEmitter<AdvanceScoutingPlayerFragment>();

    private options: AdvanceScoutingPlayerSelectOption[] = [];
    prev: AdvanceScoutingPlayerSelectOption;
    prevDisabled = false;
    next: AdvanceScoutingPlayerSelectOption;
    nextDisabled = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['groups']) {
            this.options = chain(this.groups).map('options').flatten().valueOf();
        }
        if (changes['player'] || changes['groups']) {
            const index = chain(this.options).map('value.player.ebisId').indexOf(this.player.player.ebisId).valueOf();
            this.prev = this.options[index - 1];
            this.next = this.options[index + 1];
        }
    }

    onPlayerChanged(selected: AdvanceScoutingPlayerFragment): void {
        this.playerChange.emit(selected);
    }
}
